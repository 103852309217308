import {
  to
} from "./chunk.3OP6Y2IJ.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  JBCookie
} from "./chunk.PSCGDE4H.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/mixins/Spaceable.ts
function Spaceable(baseClass) {
  class SpaceableMixin extends baseClass {
    constructor() {
      super(...arguments);
      this._wrapped = false;
    }
    connectedCallback() {
      super.connectedCallback();
      if (this.withSpacing && !this._wrapped) {
        this._addSpacing();
      }
    }
    update(changedProperties) {
      super.update(changedProperties);
      if (changedProperties.has("withSpacing")) {
        if (this.withSpacing && !this._wrapped) {
          this._addSpacing();
        } else if (!this.withSpacing && this._wrapped) {
          this._removeSpacing();
        }
      }
    }
    _addSpacing() {
      this._wrapped = true;
      const contentSpacing = new JbContentSpacing().init({
        props: this._getContentSpacingProperties()
      });
      if (this.hasAttribute("slot")) {
        contentSpacing.setAttribute("slot", this.getAttribute("slot"));
        this.removeAttribute("slot");
      }
      if (this.hasAttribute("id")) {
        contentSpacing.setAttribute("id", this.getAttribute("id"));
        this.removeAttribute("id");
      }
      if (this.hasAttribute("background")) {
        contentSpacing.setAttribute("class", this.getAttribute("background"));
        this.removeAttribute("background");
      }
      this._wrap(contentSpacing);
    }
    /**
     * override this method to specify the properties of the jb-content-spacing element, that will surround your component
     */
    _getContentSpacingProperties() {
      return {};
    }
    _removeSpacing() {
      this._wrapped = false;
      const contentSpacing = this.closest("jb-content-spacing");
      if (!contentSpacing) {
        return;
      }
      if (contentSpacing.hasAttribute("slot")) {
        this.setAttribute("slot", contentSpacing.getAttribute("slot"));
        contentSpacing.removeAttribute("slot");
      }
      if (contentSpacing.hasAttribute("id")) {
        this.setAttribute("id", contentSpacing.getAttribute("id"));
        contentSpacing.removeAttribute("id");
      }
      if (this.hasAttribute("background") && this.getAttribute("background") === "true") {
        contentSpacing.setAttribute("class", "bg-enabled");
        this.removeAttribute("background");
      }
      contentSpacing.replaceWith(this);
    }
    _wrap(wrapper) {
      this.parentNode?.insertBefore(wrapper, this);
      wrapper.appendChild(this);
    }
  }
  __decorateClass([
    n({ type: Boolean })
  ], SpaceableMixin.prototype, "withSpacing", 2);
  return SpaceableMixin;
}

// src/mixins/MarketDependent.ts
function MarketDependent(baseClass) {
  class MarketDependentMixin extends Spaceable(baseClass) {
    willUpdate(_changedProperties) {
      super.willUpdate(_changedProperties);
      if (!this.onlyMarkets) {
        return;
      }
      const cookieMarket = parseInt(JBCookie.getI18nCookieData().market, 10);
      if (this.onlyMarkets.includes(cookieMarket)) {
        this.style.display = "block";
      } else {
        this.style.display = "none";
        this.withSpacing = false;
      }
    }
  }
  __decorateClass([
    n({ type: Array })
  ], MarketDependentMixin.prototype, "onlyMarkets", 2);
  return MarketDependentMixin;
}

// src/components/jb-content-spacing/jb-content-spacing.scss?inline
var jb_content_spacing_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
.grid > div {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: var(--theme-grid-template-columns, var(--_grid-template-columns));
  width: 100%;
}
@media (min-width: 960px) {
  .grid > div {
    --_grid-gap: 40px;
  }
}

.grid {
  --_max-width: calc(100% - (2 * 6.68%));
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] minmax(0, var(--theme-grid-max-width, var(--_max-width))) [main-end] minmax(0, 1fr) [full-end];
  margin-inline: auto;
}
@media (min-width: 600px) {
  .grid {
    --_max-width: calc(100% - (2 * 8.35%));
  }
}
@media (min-width: 960px) {
  .grid {
    --_max-width: calc(100% - (2 * 6.955%));
  }
}
@media (min-width: 1437px) {
  .grid {
    --_max-width: 1437px;
  }
}

:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  padding-right: var(--compensate-scrollbar);
  --vertical-spacing-factor: 1;
  --top-spacing-factor: 1;
  --bottom-spacing-factor: 1;
}

:host(.smoke-light) {
  background: #f1f2f2;
}

:host(.stone) {
  background: #b0aa7e;
}

:host(.stone-light) {
  background: #f7f7f2;
}

:host(.violet-light) {
  background: #f2eff0;
}

section {
  padding-bottom: calc(var(--vertical-spacing-factor) * var(--bottom-spacing-factor) * 60px);
  padding-top: calc(var(--vertical-spacing-factor) * var(--top-spacing-factor) * 60px);
  position: relative;
}
@media (min-width: 1281px) {
  section {
    padding-bottom: calc(var(--vertical-spacing-factor) * var(--bottom-spacing-factor) * 68px);
    padding-top: calc(var(--vertical-spacing-factor) * var(--top-spacing-factor) * 68px);
  }
}

section:not(.grid) {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * var(--row-small-margin, 6.68%));
}
@media (min-width: 600px) and (max-width: 959px) {
  section:not(.grid) {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  section:not(.grid) {
    max-width: var(--row-max-width, 1436px);
  }
  section:not(.grid):not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  section:not(.grid) {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
section:not(.grid) div {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
}
@media (min-width: 600px) {
  section:not(.grid) div {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  section:not(.grid) div {
    margin-left: 16.6666666667%;
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 0 20px;
    width: 66.6666666667%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-6 div {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    margin-left: 25%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-9 div {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 20px;
    width: 75%;
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-10 div {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 0 20px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-12 div {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
    margin-left: 0%;
  }
}
@media (min-width: 1281px) {
  section:not(.grid).cols-lg-6 div {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    margin-left: 25%;
  }
}
@media (min-width: 1281px) {
  section:not(.grid).cols-lg-10 div {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 0 20px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-lg-12 div {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}
@media (min-width: 600px) {
  section:not(.grid).cols-none div {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).cols-none div {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 0;
    width: 100%;
    margin-left: 0%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).align-left.cols-6 div {
    margin-left: 16.6666666667%;
  }
  section:not(.grid).align-left.cols-8 div, section:not(.grid).align-left.cols-9 div, section:not(.grid).align-left.cols-10 div {
    margin-left: 0%;
  }
}
@media (min-width: 960px) {
  section:not(.grid).align-right.cols-6 div {
    margin-left: 50%;
  }
  section:not(.grid).align-right.cols-8 div {
    margin-left: 33.3333333333%;
  }
  section:not(.grid).align-right.cols-9 div {
    margin-left: 25%;
  }
  section:not(.grid).align-right.cols-10 div {
    margin-left: 16.6666666667%;
  }
}
@media (min-width: 1437px) {
  section:not(.grid).wide {
    max-width: calc(var(--row-max-width, 1436px) + 136px);
    padding-left: 68px;
    padding-right: 68px;
  }
}
section:not(.grid).full-width {
  width: 100%;
}
@media (max-width: 959px) {
  section:not(.grid).full-width-sm-down {
    width: 100%;
  }
}
@media (max-width: 1280px) {
  section:not(.grid).full-width-md-down {
    width: 100%;
  }
}
@media (max-width: 1436px) {
  section:not(.grid).full-width-lg-down {
    width: 100%;
  }
}
@media (max-width: 599px) {
  section:not(.grid).full-width-xs {
    width: 100%;
  }
}
section:not(.grid).focus.align-center div {
  padding: 0;
}

@media (min-width: 960px) {
  .grid-legacy > div {
    padding-inline: 20px;
  }
}

.grid > div {
  grid-column: main;
}
.grid > div ::slotted(*) {
  grid-column: 1/13;
}
@media (min-width: 960px) {
  .grid > div ::slotted(*) {
    grid-column: 3/span 8;
  }
}
@media (min-width: 960px) {
  .grid.cols-6 > div ::slotted(*) {
    grid-column: 4/span 6;
  }
}
@media (min-width: 960px) {
  .grid.cols-9 > div ::slotted(*) {
    grid-column: 2/span 9;
  }
}
@media (min-width: 960px) {
  .grid.cols-10 > div ::slotted(*) {
    grid-column: 2/span 10;
  }
}
@media (min-width: 960px) {
  .grid.cols-12 > div ::slotted(*) {
    grid-column: 1/span 12;
  }
}
@media (min-width: 1281px) {
  .grid.cols-lg-6 > div ::slotted(*) {
    grid-column: 4/span 6;
  }
}
@media (min-width: 1281px) {
  .grid.cols-lg-8 > div ::slotted(*) {
    grid-column: 3/span 8;
  }
}
@media (min-width: 1281px) {
  .grid.cols-lg-9 > div ::slotted(*) {
    grid-column: 2/span 9;
  }
}
@media (min-width: 1281px) {
  .grid.cols-lg-10 > div ::slotted(*) {
    grid-column: 2/span 10;
  }
}
@media (min-width: 1281px) {
  .grid.cols-lg-12 > div ::slotted(*) {
    grid-column: 1/span 12;
  }
}
.grid.cols-none > div {
  padding-inline: 0;
}
.grid.cols-none > div ::slotted(*) {
  grid-column: 1/span 12;
}
@media (min-width: 960px) {
  .grid.align-left.cols-6 > div ::slotted(*), .grid.align-left.cols-8 > div ::slotted(*), .grid.align-left.cols-9 > div ::slotted(*), .grid.align-left.cols-10 > div ::slotted(*) {
    grid-column-start: 1;
  }
}
@media (min-width: 960px) {
  .grid.align-right.cols-6 > div ::slotted(*) {
    grid-column: 7/span 6;
  }
  .grid.align-right.cols-8 > div ::slotted(*) {
    grid-column: 5/span 8;
  }
  .grid.align-right.cols-9 > div ::slotted(*) {
    grid-column: 4/span 9;
  }
  .grid.align-right.cols-10 > div ::slotted(*) {
    grid-column: 3/span 10;
  }
}
.grid.full-width > div {
  grid-column: full;
}
@media (max-width: 959px) {
  .grid.full-width-sm-down > div {
    grid-column: full;
  }
}
@media (max-width: 1280px) {
  .grid.full-width-md-down > div {
    grid-column: full;
  }
}
@media (max-width: 1436px) {
  .grid.full-width-lg-down > div {
    grid-column: full;
  }
}
@media (max-width: 599px) {
  .grid.full-width-xs > div {
    grid-column: full;
  }
}`;

// src/components/jb-content-spacing/jb-content-spacing.ts
var JbContentSpacing = class extends Initiable(r2) {
  connectedCallback() {
    super.connectedCallback();
    if (this.type) {
      this.setAttribute("data-type", this.type);
    }
  }
  async firstUpdated() {
    if (this.type === "focus") {
      this.style.setProperty("--bottom-spacing-factor", "0.5");
      if (this._followsTextComponent()) {
        this.style.setProperty("--top-spacing-factor", "0.5");
      }
    }
    if (this.type === "no-vertical-space") {
      this.style.setProperty("--vertical-spacing-factor", "0");
    }
    if (this.type === "text") {
      this.style.setProperty("--vertical-spacing-factor", "0.5");
      if (this._followsTextComponent()) {
        this.style.setProperty("--top-spacing-factor", "0");
      }
    }
    if (this.type === "half-top-no-bottom") {
      this.style.setProperty("--top-spacing-factor", "0.5");
      this.style.setProperty("--bottom-spacing-factor", "0");
    }
    if (this.type === "list") {
      if (this._followsTextComponent()) {
        this.style.setProperty("--vertical-spacing-factor", "0.5");
      }
    }
    if (this.type === "media") {
      if (this._followsTextComponent()) {
        this.style.setProperty("--vertical-spacing-factor", "0.5");
      }
    }
    if (this.type === "list-without-heading") {
      this.style.setProperty("--vertical-spacing-factor", "0.5");
      if (this._followsTextComponent()) {
        this.style.setProperty("--top-spacing-factor", "0");
      }
    }
    if (this.type === "article-wide") {
      this.style.setProperty("--bottom-spacing-factor", "0");
    }
    if (this.type === "teaser-block" || this.type === "navigation-tags") {
      if (this.getAttribute("class")) {
        if (this._followsArticleWideComponent()) {
          this.style.setProperty("margin-top", "72px");
        } else {
          this.style.setProperty("margin-block", "72px");
        }
      }
    }
  }
  _followsArticleWideComponent() {
    const previousElement = this.previousElementSibling;
    if (!previousElement) {
      return false;
    }
    const visiblePreviousElement = this._previousVisibleElement();
    if (!visiblePreviousElement) {
      return false;
    }
    return visiblePreviousElement.matches(['jb-content-spacing[data-type="article-wide"]'].join(", "));
  }
  _followsTextComponent() {
    const previousElement = this.previousElementSibling;
    if (!previousElement) {
      return false;
    }
    const visiblePreviousElement = this._previousVisibleElement();
    if (!visiblePreviousElement) {
      return false;
    }
    return visiblePreviousElement.matches(
      [
        'jb-content-spacing[data-type="text"]',
        ".template-section-spacer--text-components",
        ".template-section-spacer--richtext-components"
      ].join(", ")
    );
  }
  _isHidden(element) {
    return element.style.display === "none";
  }
  _previousVisibleElement() {
    let prevElement = this.previousElementSibling;
    while (prevElement) {
      if (!this._isHidden(prevElement)) {
        return prevElement;
      }
      prevElement = prevElement.previousElementSibling;
    }
    return null;
  }
  render() {
    return ke` <section
      class=${Rt({
      ["align-" + this.align]: !!this.align,
      ["cols-" + this.colsMdUp]: !!this.colsMdUp,
      ["cols-lg-" + this.colsLgUp]: !!this.colsLgUp,
      [to(this.horizontal)]: true,
      [to(this.type)]: true,
      "grid-legacy": this.grid === "legacy",
      grid: !!this.grid
    })}
    >
      <div><slot></slot></div>
    </section>`;
  }
};
JbContentSpacing.styles = r(jb_content_spacing_default);
__decorateClass([
  n()
], JbContentSpacing.prototype, "align", 2);
__decorateClass([
  n({ type: Number })
], JbContentSpacing.prototype, "colsLgUp", 2);
__decorateClass([
  n({ type: Number })
], JbContentSpacing.prototype, "colsMdUp", 2);
__decorateClass([
  n({ type: Boolean })
], JbContentSpacing.prototype, "grid", 2);
__decorateClass([
  n()
], JbContentSpacing.prototype, "horizontal", 2);
__decorateClass([
  n()
], JbContentSpacing.prototype, "type", 2);
JbContentSpacing = __decorateClass([
  customElement("jb-content-spacing")
], JbContentSpacing);

export {
  JbContentSpacing,
  Spaceable,
  MarketDependent
};
