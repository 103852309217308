import {
  Spaceable
} from "./chunk.D7EHW44T.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Linkable
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-wide/jb-article-wide.scss?inline
var jb_article_wide_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

@media (max-width: 959px) {
  section {
    --teaser-header-h3-margin-bottom: 20px;
  }
}

article {
  margin-bottom: 0;
  position: relative;
}
@media (min-width: 960px) {
  article {
    margin-bottom: unset;
  }
}
article .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  order: 2;
}
article .row a {
  text-decoration: none;
}
@media (max-width: 599px) {
  article .row {
    margin: 0;
    max-width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  article .row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 2 * var(--row-small-margin, 6.68%));
  }
}
@media (min-width: 600px) and (max-width: 959px) and (min-width: 600px) and (max-width: 959px) {
  article .row {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 600px) and (max-width: 959px) and (min-width: 1437px) {
  article .row {
    max-width: var(--row-max-width, 1436px);
  }
  article .row:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) and (min-width: 960px) and (max-width: 1436px) {
  article .row {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
article .item {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}
@media (min-width: 600px) {
  article .item {
    padding: 0 20px;
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}
@media (min-width: 960px) {
  article .item {
    margin-bottom: 0;
  }
}
article .item--left {
  order: 2;
}
@media (min-width: 960px) {
  article .item--left {
    order: unset;
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 20px;
    width: 33.3333333333%;
  }
}
@media (max-width: 599px) {
  article .item--left {
    padding-left: 6.68%;
    padding-right: 6.68%;
  }
}
@media (min-width: 960px) {
  article .item--right {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
  }
}
article jb-teaser-header {
  --display-teaser-text: none;
}
@media (min-width: 1281px) {
  article jb-teaser-header {
    --display-teaser-text: block;
  }
}
article .link {
  display: block;
  margin-bottom: 24px;
}
article:hover jb-picture {
  --transform-image: scale(1.05);
}
article:hover jb-thumbnail {
  --transform-thumbnail: scale(1.05);
}

.headline-wrap {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}

jb-section-headline {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
  margin-bottom: 60px;
}
@media (min-width: 600px) {
  jb-section-headline {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}
@media (min-width: 960px) {
  jb-section-headline {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 0 20px;
    width: 83.3333333333%;
    margin-bottom: 68px;
  }
}`;

// src/components/jb-article-wide/jb-article-wide.ts
var JbArticleWide = class extends Linkable(Spaceable(r2)) {
  constructor() {
    super(...arguments);
    this.picture = void 0;
  }
  connectedCallback() {
    super.connectedCallback();
    this.link.name = this.articleTitle;
  }
  _getContentSpacingProperties() {
    return { colsMdUp: "none", horizontal: "full-width-sm-down", type: "article-wide" };
  }
  _renderArticleWide() {
    return ke`<article class="${Rt({ headline: !!this.headline })}">
      <div class="row">
        <div class="item item--left">
          <a
            data-linkable
            class="link"
            >${this._renderTeaserHeader()}</a
          >
          <jb-teaser-meta
            .date=${this.teaserMeta.date}
            .category=${this.teaserMeta.category}
            .link=${this.teaserMeta.link ? this.teaserMeta.link : this.link}
            .time=${this.teaserMeta.time}
          ></jb-teaser-meta>
        </div>
        <div class="item item--right">
          <a data-linkable>${this._renderPreview()}</a>
        </div>
      </div>
    </article>`;
  }
  _renderPreview() {
    if (this.picture) {
      return ke`<jb-thumbnail
        .aspect=${"aspect_3by2"}
        .backgroundPicture=${this.picture}
        .type=${"picture"}
      ></jb-thumbnail>`;
    }
    return null;
  }
  _renderTeaserHeader() {
    return ke`<jb-teaser-header
      .maxLines=${4}
      .teaser=${this.articleTeaser}
      .headline=${this.articleTitle}
    ></jb-teaser-header>`;
  }
  render() {
    return ke`
      <section>
        ${this.headline ? ke`
              <div class="headline-wrap">
                <jb-section-headline
                  .headline=${this.headline}
                  .languageBadge=${this.languageBadge}
                ></jb-section-headline>
              </div>
            ` : D}
        ${this._renderArticleWide()}
      </section>
    `;
  }
};
JbArticleWide.styles = r(jb_article_wide_default);
__decorateClass([
  n()
], JbArticleWide.prototype, "articleTeaser", 2);
__decorateClass([
  n()
], JbArticleWide.prototype, "articleTitle", 2);
__decorateClass([
  n()
], JbArticleWide.prototype, "headline", 2);
__decorateClass([
  n()
], JbArticleWide.prototype, "languageBadge", 2);
__decorateClass([
  n({ type: Object })
], JbArticleWide.prototype, "picture", 2);
__decorateClass([
  n({ type: Object })
], JbArticleWide.prototype, "teaserMeta", 2);
JbArticleWide = __decorateClass([
  customElement("jb-article-wide")
], JbArticleWide);

export {
  JbArticleWide
};
