import type { INavigation } from '../../../../storybook/src/components/jb-navigation/jb-navigation.custom';
import { getI18nCookieData } from '../modules/i18n';

window.addEventListener('DOMContentLoaded', () => {
  const endpoint = document.body.dataset.menuEndpoint;
  if (!endpoint) {
    return;
  }

  const navigation: INavigation & HTMLElement = document.querySelector('jb-navigation');
  if (!navigation) {
    return;
  }

  const cachedI18n = localStorage.getItem('bjb-fe-menuI18n');
  const currentI18n = JSON.stringify(getI18nCookieData());
  if (cachedI18n !== currentI18n) {
    localStorage.removeItem('bjb-fe-menuData');
    localStorage.removeItem('bjb-fe-menuETag');
  }

  const cachedMenu = localStorage.getItem('bjb-fe-menuData');
  const cachedETag = localStorage.getItem('bjb-fe-menuETag');

  if (cachedMenu) {
    navigation.menu = JSON.parse(cachedMenu);
  }

  const url = endpoint.replace(':marketId', getI18nCookieData().market) + '?L=' + getI18nCookieData().language;
  fetch(url, {
    headers: cachedETag ? { 'If-None-Match': cachedETag } : {}
  })
    .then(response => {
      if (response.status === 304) {
        // Not Modified, use cached data
        return JSON.parse(cachedMenu);
      } else if (response.status === 200) {
        // OK, update cache
        const eTag = response.headers.get('ETag');
        return response.json().then(data => {
          if (eTag) {
            localStorage.setItem('bjb-fe-menuETag', eTag);
          }
          localStorage.setItem('bjb-fe-menuData', JSON.stringify(data));
          localStorage.setItem('bjb-fe-menuI18n', currentI18n);
          return data;
        });
      } else {
        throw new Error('Failed to fetch menu data');
      }
    })
    .then(data => {
      navigation.menu = data;
    })
    .catch(error => {
      console.error('Error fetching menu data:', error);
    });
});
