import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-bluesky-icon/jb-bluesky-icon.scss?inline
var jb_bluesky_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  height: var(--icon-height, 16px);
  width: var(--icon-width, 16px);
}

svg {
  height: var(--icon-height, 16px);
  width: var(--icon-width, 16px);
}`;

// src/components/icons/jb-bluesky-icon/jb-bluesky-icon.ts
var JbIBlueskyIconIcon = class extends r2 {
  render() {
    return ke`
      <svg
        fill="currentColor"
        viewBox="0 0 19 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1613 2.63693C18.1486 2.99978 18.1792 3.36898 18.1613 3.73124C18.1613 3.73933 18.1052 3.79596 18.1012 3.87049C18.029 5.12889 17.9885 7.55382 17.3339 8.57591C16.5678 9.77364 15.195 10.1625 13.8435 10.2393C13.8199 10.3878 13.8586 10.3283 13.9244 10.3595C14.2647 10.5178 14.7397 10.5721 15.1314 10.7645C18.0012 12.1703 15.8536 14.984 13.9886 16.1142C11.9039 17.3772 10.8709 16.2234 10.1579 14.3C9.94585 13.7274 9.82452 12.9537 9.59687 12.4424C9.56163 12.3638 9.58994 12.3523 9.46861 12.3702C8.91452 14.2202 7.78785 17.8562 5.16763 16.3545C3.9803 15.6739 1.73101 13.1958 2.47923 11.7421C2.93914 10.8489 4.22528 10.5571 5.12199 10.3532C5.21154 10.3329 5.22252 10.3919 5.20808 10.2393C4.39456 10.1798 3.64056 10.077 2.91141 9.68524C0.977009 8.64582 1.13705 6.52769 1.0059 4.62391C0.934254 3.5868 0.485321 1.62062 1.97021 1.38373C3.46896 1.14453 5.77603 3.26036 6.73514 4.30613C7.79421 5.46053 8.69207 6.76573 9.4975 8.10618C10.3185 6.82293 11.1748 5.55124 12.2021 4.42111C13.1704 3.35627 15.5133 1.17804 17.034 1.37507C17.8025 1.47444 17.9862 1.97018 18.1613 2.63693Z"
        />
      </svg>
    `;
  }
};
JbIBlueskyIconIcon.styles = r(jb_bluesky_icon_default);
JbIBlueskyIconIcon = __decorateClass([
  customElement("jb-bluesky-icon")
], JbIBlueskyIconIcon);

export {
  JbIBlueskyIconIcon
};
