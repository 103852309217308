import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-x-icon/jb-x-icon.scss?inline
var jb_x_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  height: var(--icon-height, 13.9px);
  width: var(--icon-width, 15.4px);
}

svg {
  height: var(--icon-height, 13.9px);
  width: var(--icon-width, 15.4px);
}`;

// src/components/icons/jb-x-icon/jb-x-icon.ts
var JbXIcon = class extends r2 {
  render() {
    return ke`
      <svg
        fill="currentColor"
        viewbox="0 0 24 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.21556 0H0L8.8455 11.5499L0.994385 20.9073H4.46193L10.5169 13.7323L16.0119 20.9073H23.1L14.27 9.28507L22.1056 0H18.5361L12.5952 7.08074L7.21556 0ZM16.9993 18.9073L4.05078 2H6.22324L19.0687 18.9073H16.9993Z"
        />
      </svg>
    `;
  }
};
JbXIcon.styles = r(jb_x_icon_default);
JbXIcon = __decorateClass([
  customElement("jb-x-icon")
], JbXIcon);

export {
  JbXIcon
};
