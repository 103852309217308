import {
  OverlayStack
} from "./chunk.VUVR64DD.js";

// src/mixins/Stackable.ts
function Stackable(baseClass) {
  class StackableMixin extends baseClass {
    constructor() {
      super(...arguments);
      this._outroCallbacks = [];
      this._stacked = false;
    }
    async intro() {
    }
    async outro() {
    }
    stack(element) {
      if (!this._stacked) {
        this._addToStack(element);
      }
    }
    stacked() {
    }
    unstack() {
      if (this._stacked) {
        this._removeFromStack();
      }
    }
    unstacked() {
    }
    zIndex() {
      const baseline = 500;
      return (this._layer || 0) * 100 + baseline;
    }
    async _addToStack(element) {
      this.addEventListener("jb-stackable:register-outro-callback", (event) => {
        const customEvent = event;
        if (customEvent.detail.callback) {
          this._outroCallbacks.push(customEvent.detail.callback);
        }
      });
      this._stacked = true;
      const lmt = document.querySelector(element);
      this._layer = OverlayStack.Instance.add(this, lmt);
      this.intro().then(() => {
        this.stacked();
      });
    }
    async _removeFromStack() {
      this._stacked = false;
      const outroPromises = [];
      this._outroCallbacks.forEach((callback) => {
        outroPromises.push(callback());
      });
      if (outroPromises.length > 0) {
        await Promise.allSettled(outroPromises);
      }
      await this.outro();
      OverlayStack.Instance.remove(this);
      this.unstacked();
    }
  }
  return StackableMixin;
}

export {
  Stackable
};
