export function addFormDataEventListener(callback) {
  window.addEventListener('jb-tracking', async (evt: CustomEvent) => {
    if (evt.detail.form_type !== 'contact') {
      return;
    }
    if (evt.detail.form_step_name !== 'form open' && evt.detail.form_step_name !== 'form type select') {
      return;
    }

    const formComponent = evt.detail.form_component;
    await formComponent.updateComplete;
    const form = formComponent.shadowRoot.querySelector('form');
    form.addEventListener('formdata', callback, { once: true });
  });
}
