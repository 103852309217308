import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  Linkable
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ButtonElement
} from "./chunk.7PRSKJZQ.js";
import {
  Oe,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-button-tertiary/jb-button-tertiary.scss?inline
var jb_button_tertiary_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
a,
button {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

a,
button {
  font-size: var(--theme-typo-uppercase-900-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-900-letterspacing, 0.125rem);
  line-height: var(--theme-typo-uppercase-900-lineheight, 1rem);
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  min-block-size: 20px;
  --_button-text-color: 20, 30, 85;
}

a,
button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: rgba(var(--_button-text-color), 1);
  cursor: pointer;
  display: inline-flex;
  gap: var(--spacing-3xs);
  inline-size: 100%;
  padding-inline: 0;
  text-align: start;
  text-decoration: none;
}
a span,
button span {
  align-items: center;
  display: inline-flex;
  inset-block-start: 5px;
  position: relative;
}
a svg,
button svg {
  display: inline-block;
  flex-basis: 19px;
  flex-shrink: 0;
  position: relative;
  transition: transform 0.3s ease-out 0s;
  vertical-align: bottom;
}
a.hover svg, a:hover svg,
button.hover svg,
button:hover svg {
  transform: translateX(8px);
}
a.up svg,
button.up svg {
  transform: rotate(-90deg);
}
a.up.hover svg, a.up:hover svg, a.up:focus svg,
button.up.hover svg,
button.up:hover svg,
button.up:focus svg {
  transform: rotate(-90deg) translateX(3px);
}
a.down svg,
button.down svg {
  transform: rotate(90deg);
}
a.down.hover svg, a.down:hover svg,
button.down.hover svg,
button.down:hover svg {
  transform: rotate(90deg) translateX(3px);
}
a.inverted,
button.inverted {
  --_button-text-color: 255, 255, 255;
}
a:active,
button:active {
  transform: scale(0.99);
}

.inactive {
  opacity: 0.4;
  pointer-events: none;
}`;

// src/components/jb-button-tertiary/jb-button-tertiary.ts
var JbButtonTertiary = class extends Initiable(ButtonElement(Linkable(r2))) {
  constructor() {
    super(...arguments);
    this._arrowIcon = Oe`<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none"><g fill="currentColor" clip-path="url(#a)"><path fill-rule="evenodd" d="M16.961 8.555a.71.71 0 0 1 0 1.003l-4.654 4.654a.71.71 0 1 1-1.003-1.003l4.153-4.152-4.153-4.152a.71.71 0 1 1 1.003-1.003l4.654 4.653Z" clip-rule="evenodd"/><path d="M1.149 8.3h15v1.5h-15z"/></g><defs><clipPath id="a"><path fill="#fff" d="M1.149 3h16v12h-16z"/></clipPath></defs></svg>`;
  }
  render() {
    const classes = {
      inactive: !!this.inactive,
      inverted: !!this.inverted,
      hover: !!this.forceHover,
      [`${this.arrowDirection}`]: !!this.arrowDirection
    };
    return this.link?.href ? ke`<a
          data-linkable
          class=${Rt(classes)}
        >
          <slot></slot> ${this._arrowIcon}
        </a>` : ke`<button
          ?disabled=${this.inactive}
          class=${Rt(classes)}
        >
          <slot></slot> ${this._arrowIcon}
        </button>`;
  }
};
JbButtonTertiary.styles = r(jb_button_tertiary_default);
__decorateClass([
  n()
], JbButtonTertiary.prototype, "arrowDirection", 2);
__decorateClass([
  n({ type: Boolean })
], JbButtonTertiary.prototype, "forceHover", 2);
__decorateClass([
  n({ type: Boolean })
], JbButtonTertiary.prototype, "inactive", 2);
__decorateClass([
  n({ type: Boolean })
], JbButtonTertiary.prototype, "inverted", 2);
__decorateClass([
  n()
], JbButtonTertiary.prototype, "name", 2);
JbButtonTertiary = __decorateClass([
  customElement("jb-button-tertiary")
], JbButtonTertiary);

export {
  JbButtonTertiary
};
