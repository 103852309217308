import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-e-service-icon/jb-e-service-icon.scss?inline
var jb_e_service_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

:host {
  font-size: var(--theme-typo-p-mini-highlight-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-highlight-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: var(--icon-display, inline-flex);
  gap: var(--spacing-3xs);
}

:host(:hover) #shackle {
  translate: 0 -3px;
}

svg {
  overflow: visible;
  translate: 0 -3px;
  z-index: 1;
}

#shackle {
  transition: translate 0.2s ease-in-out;
}`;

// src/components/icons/jb-e-service-icon/jb-e-service-icon.ts
var JbEServiceIcon = class extends r2 {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("e-service-icon:clicked", { bubbles: true, composed: true }));
    });
  }
  render() {
    return ke`
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="shackleMask">
          <rect
            x="0"
            y="-5"
            width="25"
            height="30"
            fill="white"
          />
          <rect
            x="13"
            y="8.1"
            width="5"
            height="10"
            fill="black"
          />
        </mask>
        <g mask="url(#shackleMask)">
          <rect
            x="2.6"
            y="7.6"
            width="18.8"
            height="15.8"
            rx="2.4"
            stroke="currentColor"
            stroke-width="1.2"
          />
          <path
            id="shackle"
            d="M16.4153 10.57579V6.30884C16.4153 5.05702 16.028 3.85648 15.2 2.97131C14.372 2.08615 13.1711 1.58887 12.0001 1.58887C10.8291 1.58887 9.625 2.08615 8.797 2.97131C7.96899 3.85648 7.58496 5.05702 7.58496 6.30884V7.57579"
            stroke="currentColor"
            stroke-width="1.2"
          />
        </g>
      </svg>
      <slot></slot>
    `;
  }
};
JbEServiceIcon.styles = r(jb_e_service_icon_default);
JbEServiceIcon = __decorateClass([
  customElement("jb-e-service-icon")
], JbEServiceIcon);

export {
  JbEServiceIcon
};
