import { addFormDataEventListener } from '../modules/contactForm';
import { getExternalReferrer, getUtmParameters, getUrlWithUtmParameters } from '../modules/referrerData';

const DATA_COLLECTION_LIMIT = 25;

function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

function collectReferrerData() {
  let referrerData = JSON.parse(localStorage.getItem('bjb-fe-referrerData') || '{}') as object;
  const referrer = getExternalReferrer();
  if (referrer) {
    referrerData = { ...referrerData, referrer };
  }
  const utmParameters = getUtmParameters();
  if (Object.keys(utmParameters).length > 0) {
    referrerData = { ...referrerData, utmParameters };
  }
  if (isEmpty(referrerData)) {
    return;
  }

  localStorage.setItem('bjb-fe-referrerData', JSON.stringify(referrerData));
}

window.addEventListener('DOMContentLoaded', () => {
  collectReferrerData();
});

addFormDataEventListener((evt: FormDataEvent) => {
  const referrerData = localStorage.getItem('bjb-fe-referrerData');
  if (evt.formData.has('referrerData') || !referrerData) {
    return;
  }
  evt.formData.append('referrerData', referrerData);
});

function populateDataCollection(storageKey: string, value: string | null) {
  if (value === null) {
    return;
  }

  const now = Date.now();
  let dataCollection = JSON.parse(localStorage.getItem(storageKey) || '[]') as Array<{ value: string; timestamp: number }>;
  if (!Array.isArray(dataCollection)) {
    dataCollection = [];
  }

  // update the timestamp if last entry is identical with incoming value on multiple page visits
  if (dataCollection.length > 0 && dataCollection[dataCollection.length - 1].value === value) {
    dataCollection[dataCollection.length - 1].timestamp = now;
  } else {
    dataCollection.push({ value, timestamp: now });
  }

  if (dataCollection.length > DATA_COLLECTION_LIMIT) {
    dataCollection = dataCollection.slice(-DATA_COLLECTION_LIMIT);
  }

  localStorage.setItem(storageKey, JSON.stringify(dataCollection));
}

function addFormDataCollection(storageKey: string, fieldName: string) {
  addFormDataEventListener((evt: FormDataEvent) => {
    const storedData = localStorage.getItem(storageKey);
    if (evt.formData.has(fieldName) || !storedData) {
      return;
    }
    evt.formData.append(fieldName, storedData);
  });
}

window.addEventListener('DOMContentLoaded', () => {
  populateDataCollection('bjb-fe-referrerDataCollection', getExternalReferrer());
  populateDataCollection('bjb-fe-utmDataCollection', getUrlWithUtmParameters());
});

addFormDataCollection('bjb-fe-referrerDataCollection', 'referrerDataCollection');
addFormDataCollection('bjb-fe-utmDataCollection', 'utmDataCollection');
