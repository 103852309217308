import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-social-icons/jb-social-icons.scss?inline
var jb_social_icons_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--social-icons-gap, var(--spacing-2xs));
  justify-content: center;
}
@media (min-width: 1281px) {
  ul {
    gap: var(--social-icons-gap, var(--spacing-s));
  }
}
ul li {
  block-size: 40px;
  inline-size: 40px;
  list-style: none;
}
ul li jb-anchor {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
ul li:hover jb-anchor {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}`;

// src/components/jb-social-icons/jb-social-icons.ts
var JbSocialIcons = class extends r2 {
  constructor() {
    super(...arguments);
    this.items = void 0;
  }
  _clickSocialIcon(event, service, context) {
    this.dispatchEvent(
      new CustomEvent("jb-social-icons:clicked", {
        detail: { service, context },
        bubbles: true,
        composed: true
      })
    );
  }
  /**
   * Returns the corresponding social icon custom element based on the service name.
   */
  _getSocialIcon(service) {
    switch (service) {
      case "bluesky":
        return `<jb-bluesky-icon></jb-bluesky-icon>`;
      case "email":
        return `<jb-mail-icon></jb-mail-icon>`;
      case "facebook":
        return `<jb-facebook-icon></jb-facebook-icon>`;
      case "instagram":
        return `<jb-instagram-icon></jb-instagram-icon>`;
      case "linkedin":
        return `<jb-linkedin-icon></jb-linkedin-icon>`;
      case "mail":
        return `<jb-mail-icon></jb-mail-icon>`;
      case "twitter":
        return `<jb-x-icon></jb-x-icon>`;
      case "x":
        return `<jb-x-icon></jb-x-icon>`;
      case "youtube":
        return `<jb-youtube-icon></jb-youtube-icon>`;
      default:
        return "";
    }
  }
  /**
   * Maps each social icon option to a list item that contains a anchor element with the icon.
   */
  _renderSocialIcons() {
    return this.items?.map((item) => {
      const anchor = document.createElement("jb-anchor");
      anchor.setAttribute("data-fly-padding", "0");
      anchor.link = item.link;
      anchor.innerHTML = this._getSocialIcon(item.service);
      anchor.classList.add("link");
      return ke`<li
        class="${item.service}"
        @click=${(e) => this._clickSocialIcon(e, item.service, item)}
      >
        ${anchor}
      </li>`;
    });
  }
  render() {
    return ke`
      <ul>
        ${this._renderSocialIcons()}
      </ul>
    `;
  }
};
JbSocialIcons.styles = r(jb_social_icons_default);
__decorateClass([
  n({ type: Array })
], JbSocialIcons.prototype, "items", 2);
JbSocialIcons = __decorateClass([
  customElement("jb-social-icons")
], JbSocialIcons);

export {
  JbSocialIcons
};
