import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-print-icon/jb-print-icon.scss?inline
var jb_print_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  height: var(--icon-size, 18px);
  width: var(--icon-size, 18px);
}

svg {
  height: var(--icon-height, 18px);
  transition: var(--icon-transition, none);
  width: var(--icon-width, 18px);
}`;

// src/components/icons/jb-print-icon/jb-print-icon.ts
var JbPrintIcon = class extends r2 {
  render() {
    return ke`
      <svg
        fill="currentColor"
        viewbox="0 0 22 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 11.12H4v3.75c0 .138.112.25.25.25h13.5a.25.25 0 0 0 .25-.25zm-4.316-7.686h3.303L13.684.935zm4.542.938l-.333-.252H13.57a.575.575 0 0 1-.57-.58V.929H3.774V6.12h14.452zM.25 15.12c-.138 0-.25-.097-.25-.217V6.337c0-.12.112-.217.25-.217H3V.631C3 .35 3.22.12 3.49.12h10.276c.104 0 .204.034.288.097l4.744 3.59c.127.096.202.25.202.414V6.12h2.75c.138 0 .25.097.25.217v8.566c0 .12-.112.217-.25.217H19v.743c0 .142-.12.257-.267.257H3.267A.262.262 0 0 1 3 15.863v-.743zm.75-8v7h2v-3.743c0-.142.12-.257.267-.257h15.466c.148 0 .267.115.267.257v3.743h2v-7z"
        ></path>
      </svg>
    `;
  }
};
JbPrintIcon.styles = r(jb_print_icon_default);
JbPrintIcon = __decorateClass([
  customElement("jb-print-icon")
], JbPrintIcon);

export {
  JbPrintIcon
};
