import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-calendar-icon/jb-calendar-icon.scss?inline
var jb_calendar_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  height: var(--icon-size, 18px);
  width: var(--icon-size, 18px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
}`;

// src/components/icons/jb-calendar-icon/jb-calendar-icon.ts
var JbCalendarIcon = class extends r2 {
  render() {
    return ke`
      <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.30841 8.27068C2.30841 8.63201 2.01553 8.92489 1.6542 8.92489C1.29287 8.92489 0.999996 8.63201 0.999996 8.27068V3.30833C1.00003 2.0355 2.03555 1 3.30838 1H14.6798C15.9526 1 16.9882 2.0355 16.9882 3.30833V8.27068C16.9882 8.63201 16.6953 8.92489 16.334 8.92489C15.9727 8.92489 15.6798 8.63201 15.6798 8.27068V3.30833C15.6798 2.75698 15.2312 2.30841 14.6798 2.30841H3.30835C2.75698 2.30841 2.30841 2.75698 2.30841 3.30833V8.27068ZM5 6C5 5.44772 5.44772 5 6 5C6.55229 5 7 5.44772 7 6C7 6.55228 6.55229 7 6 7C5.44772 7 5 6.55228 5 6ZM1.6542 9C2.01553 9 2.3084 9.29288 2.3084 9.65421V14.6166C2.3084 15.1679 2.75697 15.6165 3.30834 15.6165H14.6799C15.2312 15.6165 15.6798 15.1679 15.6798 14.6166V9.65421C15.6798 9.29288 15.9727 9 16.334 9C16.6953 9 16.9882 9.29288 16.9882 9.65421V14.6166C16.9882 15.8894 15.9526 16.9249 14.6798 16.9249H3.30838C2.03555 16.9249 1.00002 15.8894 0.999992 14.6166V9.65421C0.999992 9.29288 1.29287 9 1.6542 9ZM5 9C5 8.44772 5.44772 8 6 8C6.55229 8 7 8.44772 7 9C7 9.55228 6.55229 10 6 10C5.44772 10 5 9.55228 5 9ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13C6.55229 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11ZM8 6C8 5.44772 8.44772 5 9 5C9.55229 5 10 5.44772 10 6C10 6.55228 9.55229 7 9 7C8.44772 7 8 6.55228 8 6ZM9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10C9.55229 10 10 9.55228 10 9C10 8.44772 9.55229 8 9 8ZM8 12C8 11.4477 8.44772 11 9 11C9.55229 11 10 11.4477 10 12C10 12.5523 9.55229 13 9 13C8.44772 13 8 12.5523 8 12ZM12 5C11.4477 5 11 5.44772 11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5ZM11 9C11 8.44772 11.4477 8 12 8C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9ZM12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11Z"
        />
      </svg>
    `;
  }
};
JbCalendarIcon.styles = r(jb_calendar_icon_default);
JbCalendarIcon = __decorateClass([
  customElement("jb-calendar-icon")
], JbCalendarIcon);

export {
  JbCalendarIcon
};
