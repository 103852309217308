export function getExternalReferrer() {
  const { referrer } = document;
  if (referrer.indexOf(window.location.hostname) !== -1 || referrer === '') {
    return null;
  }
  return referrer;
}

export function getUtmParameters() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utmParams: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  });
  return utmParams;
}

export function getUrlWithUtmParameters(): string | null {
  const baseUrl = window.location.origin + window.location.pathname;
  const utmParameters = getUtmParameters();
  if (Object.keys(utmParameters).length === 0) {
    return null;
  }
  const utmQueryString = new URLSearchParams(utmParameters).toString();
  return `${baseUrl}?${utmQueryString}`;
}
