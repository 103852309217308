import { JBCookie } from '@bjb/bjb-web-components/dist/assets/scripts/JB.js';
import { addFormDataEventListener } from '../modules/contactForm';

window.addEventListener('experienceCloudIdReady', function(e: CustomEvent) {
  if (!e.detail.ecid) {
    return;
  }

  localStorage.setItem('bjb-fe-ecid', e.detail.ecid);
});

addFormDataEventListener((evt: FormDataEvent) => {
  if (!JBCookie.getCookie('allow_tracking_cookies') && !JBCookie.getCookie('allow_marketing_cookies')) {
    return;
  }
  const ecid = localStorage.getItem('bjb-fe-ecid');
  if (evt.formData.has('ecid') || !ecid) {
    return;
  }
  evt.formData.append('ecid', ecid);
});
