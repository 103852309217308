import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-share-bar/jb-article-share-bar.scss?inline
var jb_article_share_bar_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.title {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  --social-icons-gap: 0;
  align-items: center;
  block-size: 40px;
  display: flex;
  gap: var(--spacing-3xs);
  justify-content: flex-start;
}
@media (min-width: 600px) {
  :host {
    justify-content: flex-end;
  }
}

.title {
  color: #141e55;
  display: inline-block;
  font-size: 0.688rem;
  letter-spacing: 0.125rem;
  text-transform: capitalize;
  user-select: none;
}`;

// src/components/jb-article-share-bar/jb-article-share-bar.ts
var JbArticleShareBar = class extends r2 {
  _getShareLinkByService(item) {
    switch (item.service) {
      case "bluesky":
        return {
          href: `https://bsky.app/intent/compose?text=${encodeURI(this.url)}`,
          target: "_blank",
          name: this.ariaLabel ?? "Bluesky"
        };
      case "mail":
        return {
          href: `mailto:?subject=[${this.mailSubject}]%20${encodeURI(this.articleTitle)}&amp;body=${this.url}`,
          name: this.ariaLabel ?? "Mail"
        };
      case "facebook":
        return {
          href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.url)}`,
          target: "_blank",
          name: this.ariaLabel ?? "Facebook"
        };
      case "linkedin":
        return {
          href: `https://www.linkedin.com/shareArticle/?mini=true&amp;url=${encodeURI(this.url)}&amp;title=${encodeURI(this.articleTitle)}&amp;summary=&amp;source=web`,
          target: "_blank",
          name: this.ariaLabel ?? "LinkedIn"
        };
      case "twitter":
        return {
          href: `https://twitter.com/share?text=${encodeURI(`${this.articleTitle} ${this.url}`)}`,
          target: "_blank",
          name: this.ariaLabel ?? "X"
        };
      default:
        return { href: "#", name: this.ariaLabel ?? "X" };
    }
  }
  _renderShareIcons(items) {
    const iconItems = items.map((item) => ({
      service: item.service,
      link: this._getShareLinkByService(item)
    }));
    return ke`<jb-social-icons
      .items=${iconItems}
      @jb-social-icons:clicked=${(e) => this._trackClick(e)}
    ></jb-social-icons>`;
  }
  _trackClick(event) {
    this.dispatchEvent(
      new CustomEvent("jb-tracking", {
        detail: {
          event: "ce_interaction",
          event_version: "v2",
          component_name: "article-share-bar",
          interaction_effect: "share",
          interaction_element: "icon",
          interaction_result: event.detail.service,
          interaction_type: "click"
        },
        bubbles: true,
        composed: true
      })
    );
  }
  render() {
    if (this.shareServices.length === 0) {
      return "";
    }
    return ke`
      ${this.title ? ke`<span class="title">${this.title.toLowerCase()}</span>` : D}
      ${this._renderShareIcons(this.shareServices)}
    `;
  }
};
JbArticleShareBar.styles = r(jb_article_share_bar_default);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "articleTitle", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "mailSubject", 2);
__decorateClass([
  n({ type: Array })
], JbArticleShareBar.prototype, "shareServices", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "title", 2);
__decorateClass([
  n()
], JbArticleShareBar.prototype, "url", 2);
JbArticleShareBar = __decorateClass([
  customElement("jb-article-share-bar")
], JbArticleShareBar);

export {
  JbArticleShareBar
};
