import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Linkable,
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-text-teaser/jb-text-teaser.scss?inline
var jb_text_teaser_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p, h3 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h3 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 1.375rem);
  line-height: var(--theme-typo-h3-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 1.625rem);
    line-height: var(--theme-typo-h3-desktop-lineheight, 2.25rem);
  }
}

p {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  background-color: #fff;
  block-size: 100%;
  padding-block: var(--spacing-s);
  padding-inline: var(--spacing-m);
}
div.gray {
  background-color: #f1f2f2;
}

h3 {
  padding-block-end: var(--spacing-3xs);
}

p {
  padding-block-end: var(--spacing-xs);
}

a {
  text-decoration: none;
}

jb-button-tertiary {
  padding-block: var(--spacing-3xs);
}`;

// src/components/jb-text-teaser/jb-text-teaser.ts
var JbTextTeaser = class extends Linkable(r2) {
  constructor() {
    super(...arguments);
    this._hovering = false;
  }
  _hoverLinkIn() {
    this._hovering = true;
  }
  _hoverLinkOut() {
    this._hovering = false;
  }
  _trackClick(event) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(event, {
      component_name: "text-teaser",
      interaction_text: this.buttonLabel
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <a
        data-linkable
        @jb-link:clicked=${this._trackClick}
        ><div
          @mouseout=${this._hoverLinkOut}
          @mouseover=${this._hoverLinkIn}
          class=${Rt({
      gray: !!this.hasGrayBackground
    })}
        >
          <h3>${this.headline}</h3>
          <p>${this.text}</p>
          <jb-button-tertiary
            tabindex="-1"
            .forceHover=${this._hovering}
            >${this.buttonLabel}</jb-button-tertiary
          >
        </div></a
      >
    `;
  }
};
JbTextTeaser.styles = r(jb_text_teaser_default);
__decorateClass([
  n()
], JbTextTeaser.prototype, "buttonLabel", 2);
__decorateClass([
  n({ type: Boolean })
], JbTextTeaser.prototype, "hasGrayBackground", 2);
__decorateClass([
  n()
], JbTextTeaser.prototype, "headline", 2);
__decorateClass([
  n()
], JbTextTeaser.prototype, "text", 2);
__decorateClass([
  r3()
], JbTextTeaser.prototype, "_hovering", 2);
JbTextTeaser = __decorateClass([
  customElement("jb-text-teaser")
], JbTextTeaser);

export {
  JbTextTeaser
};
