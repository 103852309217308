import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Spaceable
} from "./chunk.D7EHW44T.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  JBCookie
} from "./chunk.PSCGDE4H.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-info-box/jb-info-box.scss?inline
var jb_info_box_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-size: var(--theme-typo-h2-subtitle-mobile-fontsize, 1.625rem);
  line-height: var(--theme-typo-h2-subtitle-mobile-lineheight, 2.188rem);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-subtitle-desktop-fontsize, 1.938rem);
    line-height: var(--theme-typo-h2-subtitle-desktop-lineheight, 2.625rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

h2 {
  margin-block-end: var(--spacing-xs);
}

div {
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: var(--spacing-m);
}

.isbordered {
  border-color: rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
}

.bg-royalblue-light {
  background-color: rgb(231, 232, 238);
}

.bg-smoke-light {
  background-color: #f1f2f2;
}

.bg-stone-light {
  background-color: #f7f7f2;
}

jb-button-secondary {
  inline-size: 100%;
  margin-block-start: var(--spacing-lm);
}
@media (min-width: 960px) {
  jb-button-secondary {
    inline-size: auto;
  }
}

.in-text {
  background-color: #f7f7f2;
  border: 0;
}
.in-text jb-richtext {
  --textarea-paragraph-margin-bottom: 0;
}`;

// src/components/jb-info-box/jb-info-box.ts
var JbInfoBox = class extends Initiable(Spaceable(r2)) {
  constructor() {
    super(...arguments);
    this.isInText = false;
  }
  firstUpdated() {
    if (this.countryCondition && this.countryCondition.toLowerCase() !== JBCookie.getI18nCookieData().country.toLowerCase()) {
      this.style.setProperty("display", "none");
    }
  }
  _getContentSpacingProperties() {
    return { type: "text" };
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "info-box"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <div
        class="${Rt({
      "in-text": !!this.isInText,
      [`bg-${this.background}`]: this.background,
      isbordered: !this.background
    })}"
      >
        ${this.headline ? ke`<h2>${this.headline}</h2>` : D}
        <jb-richtext @jb-link:clicked=${this._tracking}>${ae(this.content)}</jb-richtext>
        ${this.button ? ke`<jb-button-secondary
              .link=${this.button.link}
              @jb-link:clicked=${this._tracking}
              >${this.buttonText}</jb-button-secondary
            >` : D}
      </div>
    `;
  }
};
JbInfoBox.styles = r(jb_info_box_default);
__decorateClass([
  n()
], JbInfoBox.prototype, "background", 2);
__decorateClass([
  n({ type: Object })
], JbInfoBox.prototype, "button", 2);
__decorateClass([
  n()
], JbInfoBox.prototype, "buttonText", 2);
__decorateClass([
  n()
], JbInfoBox.prototype, "content", 2);
__decorateClass([
  n()
], JbInfoBox.prototype, "countryCondition", 2);
__decorateClass([
  n()
], JbInfoBox.prototype, "headline", 2);
__decorateClass([
  n({ type: Boolean })
], JbInfoBox.prototype, "isInText", 2);
JbInfoBox = __decorateClass([
  customElement("jb-info-box")
], JbInfoBox);

export {
  JbInfoBox
};
