import {
  Linkable,
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-moment-teaser-item/jb-moment-teaser-item.scss?inline
var jb_moment_teaser_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h3 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h3 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 1.375rem);
  line-height: var(--theme-typo-h3-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  h3 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 1.625rem);
    line-height: var(--theme-typo-h3-desktop-lineheight, 2.25rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

a {
  text-decoration: none;
}

.content {
  border-left: 1px solid #141e55;
  margin-left: 20px;
  padding-left: 20px;
  padding-top: 20px;
  width: calc(100% - 40px);
}

h3 {
  margin-bottom: 8px;
}`;

// src/components/jb-moment-teaser-item/jb-moment-teaser-item.ts
var JbMomentTeaserItem = class extends Linkable(r2) {
  constructor() {
    super(...arguments);
    this._hovering = false;
  }
  _hoverLinkIn() {
    this._hovering = true;
  }
  _hoverLinkOut() {
    this._hovering = false;
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "image-page-teaser"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`<a
      @jb-link:clicked=${this._tracking}
      @mouseout=${this._hoverLinkOut}
      @mouseover=${this._hoverLinkIn}
      data-linkable
    >
      ${this.image ? ke`<jb-picture
            .alt=${this.button?.text}
            .imgData=${this.image.imgData}
            aspect="aspect_21by9"
            fit="cover"
            ?lazy=${this.image.lazy}
          ></jb-picture>` : D}
      <div class="content">
        <h3>${this.headline}</h3>
        <div class="buttons">
          ${this.button ? ke`<jb-button
                .forceHover=${this._hovering}
                .icon=${this.button.icon}
                .text=${this.button.text}
                .variant=${this.button.variant}
              ></jb-button>` : D}
        </div>
      </div>
    </a>`;
  }
};
JbMomentTeaserItem.styles = r(jb_moment_teaser_item_default);
__decorateClass([
  n({ type: Object })
], JbMomentTeaserItem.prototype, "button", 2);
__decorateClass([
  n()
], JbMomentTeaserItem.prototype, "headline", 2);
__decorateClass([
  n({ type: Object })
], JbMomentTeaserItem.prototype, "image", 2);
__decorateClass([
  n({ type: Boolean })
], JbMomentTeaserItem.prototype, "randomized", 2);
__decorateClass([
  r3()
], JbMomentTeaserItem.prototype, "_hovering", 2);
JbMomentTeaserItem = __decorateClass([
  customElement("jb-moment-teaser-item")
], JbMomentTeaserItem);

export {
  JbMomentTeaserItem
};
