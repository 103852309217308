import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-search-icon/jb-search-icon.scss?inline
var jb_search_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

:host {
  font-size: var(--theme-typo-p-mini-highlight-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-highlight-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: var(--display, inline-flex);
  gap: var(--spacing-3xs);
}

:host(:hover) svg {
  transform: rotate(75deg);
}

svg {
  block-size: 25px;
  display: inline-block;
  inline-size: 25px;
  margin-inline-end: -3px;
  overflow: visible;
  transform: rotate(0);
  transition: transform 0.5s ease-in-out 0s;
}`;

// src/components/icons/jb-search-icon/jb-search-icon.ts
var JbSearchIcon = class extends r2 {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("search-icon:clicked", { bubbles: true, composed: true }));
    });
  }
  render() {
    return ke`
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8.5"
          cy="8.5"
          r="7.87"
          stroke="currentColor"
          stroke-width="1.26"
        />
        <path
          d="M22.4346 23.4954C22.7275 23.7882 23.2023 23.7882 23.4952 23.4954C23.7881 23.2025 23.7881 22.7276 23.4952 22.4347L22.4346 23.4954ZM13.8669 14.9277L22.4346 23.4954L23.4952 22.4347L14.9275 13.867L13.8669 14.9277Z"
          fill="currentColor"
        />
      </svg>
      <slot></slot>
    `;
  }
};
JbSearchIcon.styles = r(jb_search_icon_default);
JbSearchIcon = __decorateClass([
  customElement("jb-search-icon")
], JbSearchIcon);

export {
  JbSearchIcon
};
