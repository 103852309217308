import {
  Spaceable
} from "./chunk.D7EHW44T.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-info-page-hero/jb-info-page-hero.scss?inline
var jb_info_page_hero_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
p {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h1 {
  font-family: var(--theme-font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-xlight-weight, 200);
}

h1 {
  font-size: var(--theme-typo-h1-overview-mobile-fontsize, 1.75rem);
  letter-spacing: var(--theme-typo-h1-overview-mobile-letterspacing, 0.094rem);
  line-height: var(--theme-typo-h1-overview-mobile-lineheight, 2.5rem);
}
@media (min-width: 1281px) {
  h1 {
    font-size: var(--theme-typo-h1-overview-desktop-fontsize, 3.125rem);
    letter-spacing: var(--theme-typo-h1-overview-desktop-letterspacing, 0.25rem);
    line-height: var(--theme-typo-h1-overview-desktop-lineheight, 4.375rem);
  }
}

p {
  font-size: var(--theme-typo-p-big-mobile-fontsize, 1.188rem);
  line-height: var(--theme-typo-p-big-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  p {
    font-size: var(--theme-typo-p-big-desktop-fontsize, 1.375rem);
    line-height: var(--theme-typo-p-big-desktop-lineheight, 2.125rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

div {
  padding: 0;
  text-align: center;
}

h1 {
  margin-block: 40px;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  h1 {
    margin-bottom: 20px;
    margin-top: 0;
  }
}

p {
  margin: 32px auto 0;
}
@media (min-width: 1281px) {
  p {
    margin-bottom: 20px;
  }
}

::slotted(jb-breadcrumbs) {
  display: block;
  margin-bottom: 24px;
}
@media (min-width: 1281px) {
  ::slotted(jb-breadcrumbs) {
    margin-bottom: 50px;
  }
}`;

// src/components/jb-info-page-hero/jb-info-page-hero.ts
var JbInfoPageHero = class extends Spaceable(r2) {
  _getContentSpacingProperties() {
    return { colsMdUp: 10, type: "no-vertical-space" };
  }
  render() {
    return ke`
      <div>
        <slot></slot>
        ${this.header ? ke`<h1>${this.header}</h1>` : D}
        ${this.subheader ? ke`<p>${this.subheader}</p>` : D}
      </div>
    `;
  }
};
JbInfoPageHero.styles = r(jb_info_page_hero_default);
__decorateClass([
  n()
], JbInfoPageHero.prototype, "header", 2);
__decorateClass([
  n()
], JbInfoPageHero.prototype, "subheader", 2);
JbInfoPageHero = __decorateClass([
  customElement("jb-info-page-hero")
], JbInfoPageHero);

export {
  JbInfoPageHero
};
