import { addFormDataEventListener } from '../modules/contactForm';

function collectCampaignData(): void {
  const now = Date.now();
  let campaignData = JSON.parse(localStorage.getItem('bjb-fe-campaignData') || '[]') as Array<{ value: string; timestamp: number }>;

  if (!Array.isArray(campaignData)) {
    campaignData = [];
  }

  // update the timestamp if last entry is identical with incoming value on multiple page visits
  if (campaignData.length > 0 && campaignData[campaignData.length - 1].value === pageData.jb_campaign) {
    campaignData[campaignData.length - 1].timestamp = now;
  } else {
    campaignData.push({ value: pageData.jb_campaign, timestamp: now });
  }

  // Limit to the last 25 entries
  if (campaignData.length > 25) {
    campaignData = campaignData.slice(-25);
  }

  localStorage.setItem('bjb-fe-campaignData', JSON.stringify(campaignData));
}

window.addEventListener('DOMContentLoaded', () => {
  if (typeof pageData !== 'undefined' && pageData.jb_campaign) {
    collectCampaignData();
  }
});

addFormDataEventListener((evt: FormDataEvent) => {
  const campaignData = localStorage.getItem('bjb-fe-campaignData');
  if (evt.formData.has('campaignData') || !campaignData) {
    return;
  }
  evt.formData.append('campaignData', campaignData);
});
