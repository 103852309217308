function getCookie(name: string) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function setCookie(name: string, value: string, days: number = 182, subdomainWhitelist: boolean = false) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);

  const currentHost = window.location.hostname;
  const domain = subdomainWhitelist ? 'domain=' + currentHost.slice(currentHost.indexOf('.')) : '';
  const cookie = `${name}=${value};secure;SameSite=Strict;path=/;expires=${d.toUTCString()};${domain}`;
  document.cookie = cookie;
}

export function getI18nCookieData() {
  const cookieValue = getCookie('i18n') || '||0||';
  const parts = cookieValue.split('|');
  return {
    country: parts[1],
    language: parts[2],
    languageIso2: parts[3],
    market: parts[4],
    region: parts[0],
  };
}

export function setI18nCookieData(insertData) {
  const cookieData: any = getI18nCookieData();
  Object.keys(insertData).forEach((key) => {
    // @ts-ignore
    cookieData[key] = insertData[key];
  });
  const cookieValue =
    (cookieData.region || '') +
    '|' +
    (cookieData.country || '') +
    '|' +
    (cookieData.language.toString() || '') +
    '|' +
    (cookieData.languageIso2 || '') +
    '|' +
    (cookieData.market || '');
  setCookie('i18n', cookieValue, 182);
}
