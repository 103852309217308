import {
  Linkable
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-breadcrumbs-item/jb-breadcrumbs-item.scss?inline
var jb_breadcrumbs_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
jb-anchor::part(anchor) {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

jb-anchor[inactive]::part(anchor) {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

jb-anchor::part(anchor) {
  font-size: var(--theme-typo-uppercase-700-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-700-lineheight, 1rem);
  text-transform: uppercase;
}

jb-anchor[inactive]::part(anchor) {
  font-size: var(--theme-typo-uppercase-400-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-400-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-400-lineheight, 1rem);
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host,
::before,
::after {
  --theme-typo-uppercase-700-fontsize: 0.75rem;
}

span {
  display: inline-block;
  padding-left: var(--padding-left, 0);
  position: relative;
}
span.disabled {
  pointer-events: none;
}
span ::after {
  background-color: currentcolor;
  border-radius: 100%;
  content: "";
  display: var(--dot-display, none);
  height: 3px;
  position: absolute;
  right: -13px;
  top: 53%;
  transform: translateY(-53%);
  width: 3px;
}

a {
  text-decoration: none;
}

jb-anchor::part(anchor) {
  text-decoration-color: color-mix(in srgb, currentcolor, transparent 100%);
  text-decoration-thickness: 7.5%;
  text-transform: uppercase;
  text-underline-offset: 40%;
  text-underline-position: from-font;
  transition: ease-in-out 0.2s text-decoration-color;
}
jb-anchor::part(anchor):hover {
  text-decoration-color: color-mix(in srgb, currentcolor, transparent 30%);
}

@supports not (text-decoration-color: color-mix(in srgb, currentcolor, transparent 100%)) {
  jb-anchor::part(anchor) {
    text-decoration-color: transparent;
  }
  jb-anchor::part(anchor):hover {
    text-decoration-color: currentcolor;
  }
}
jb-anchor[inactive]::part(anchor) {
  opacity: 0.5;
}`;

// src/components/jb-breadcrumbs-item/jb-breadcrumbs-item.ts
var JbBreadcrumbsItem = class extends Linkable(r2) {
  constructor() {
    super(...arguments);
    this.inactive = false;
  }
  render() {
    return ke`
      <span role="listitem">
        <jb-anchor
          flyPadding="0"
          ?inactive=${this.inactive || !this.link?.href}
          .link=${this.link}
          >${this.text}
        </jb-anchor>
      </span>
    `;
  }
};
JbBreadcrumbsItem.styles = r(jb_breadcrumbs_item_default);
__decorateClass([
  n({ type: Boolean })
], JbBreadcrumbsItem.prototype, "inactive", 2);
__decorateClass([
  n()
], JbBreadcrumbsItem.prototype, "text", 2);
JbBreadcrumbsItem = __decorateClass([
  customElement("jb-breadcrumbs-item")
], JbBreadcrumbsItem);

export {
  JbBreadcrumbsItem
};
