import {
  focusable
} from "./chunk.VGPU65F5.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  debounce,
  getAllParents
} from "./chunk.PSCGDE4H.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-focus-fly/jb-focus-fly.scss?inline
var jb_focus_fly_default = i`#focusfly {
  border: var(--_focus-fly-border-width, 2px) solid rgb(var(--theme-color-signal-blue, 0 37 255));
  box-shadow: 0 4px 64px 0 rgb(var(--theme-color-signal-blue, 0 37 255)/0.25);
  left: 0;
  opacity: 0;
  padding: var(--_focus-fly-padding, 0);
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(calc(-1 * (var(--_focus-fly-border-width, 2px) + var(--_focus-fly-padding, 0px))), calc(-1 * (var(--_focus-fly-border-width, 2px) + var(--_focus-fly-padding, 0px))));
  transition: translate 0.15s ease-out, height 0.15s ease-out, width 0.15s ease-out, opacity 0.15s ease-out;
  z-index: 9999;
}
#focusfly::after {
  border: var(--_focus-fly-border-width, 2px) solid white;
  content: "";
  inset: 0;
  position: absolute;
}
#focusfly.visible {
  opacity: 1;
}
#focusfly.ondark {
  box-shadow: 0 4px 64px 0 rgb(var(--theme-color-signal-blue, 0 37 255)/0.5);
}

@media (prefers-reduced-motion) {
  #focusfly {
    transition: none;
  }
}`;

// src/components/jb-focus-fly/jb-focus-fly.ts
var JbFocusFly = class extends r2 {
  constructor() {
    super(...arguments);
    this.defaultPadding = 4;
    this.validKeys = ["Escape", "Tab", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
    this._debouceGetAndAnimateFocus = debounce(this._getAndAnimateFocus.bind(this), 50);
    this._defaultPaddingAdd = 2;
    this._flyTweak = [];
    this._onDarkBackground = false;
    this._visible = false;
  }
  connectedCallback() {
    super.connectedCallback();
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    if (!mediaQuery.matches) {
      document.documentElement.style.setProperty("scroll-behavior", "smooth");
    }
    window.addEventListener("keyup", this._handleKeyboardEvents.bind(this));
    window.addEventListener("mouseup", () => {
      this._visible = false;
    });
    window.addEventListener("resize", this._debouceGetAndAnimateFocus);
    document.addEventListener("jb-focus-fly:reset", this._getAndAnimateFocus.bind(this));
    document.addEventListener("jb-focus-fly:hide", this._hideFly.bind(this));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("keyup", this._handleKeyboardEvents.bind(this));
    window.removeEventListener("resize", this._debouceGetAndAnimateFocus);
    document.removeEventListener("jb-focus-fly:reset", this._getAndAnimateFocus.bind(this));
    document.removeEventListener("jb-focus-fly:hide", this._hideFly.bind(this));
  }
  firstUpdated() {
    this._setFocusStyles();
  }
  _getActiveElement(root) {
    const elm = root.activeElement;
    if (!this._customPadding && elm.dataset.flyPadding) {
      this._customPadding = parseInt(elm.dataset.flyPadding, 10);
    }
    if (this._flyTweak.length === 0 && elm.dataset.flyTweak) {
      this._flyTweak = elm.dataset.flyTweak.split(",");
    }
    if (!elm.shadowRoot?.activeElement) {
      return elm;
    }
    return this._getActiveElement(elm.shadowRoot);
  }
  _getAndAnimateFocus() {
    this._customPadding = void 0;
    this._flyTweak = [];
    this._setFocusStyles();
    let elm = this._getActiveElement(document);
    const prnts = getAllParents(elm);
    prnts.forEach((prnt) => {
      if (prnt.dataset?.flyCatcher !== void 0) {
        elm = prnt;
      }
    });
    if (!elm) {
      this._visible = false;
      return;
    }
    if (elm.getAttribute("type") === "hidden") return;
    if (elm.tagName === "BODY") {
      this._visible = false;
      return;
    }
    this._onDarkBackground = this._parseColor(this._getElementBackground(elm)).hsp < 127.5;
    const elmRect = elm.getBoundingClientRect();
    let horizontalAdd;
    let horizontalTranslate;
    let verticalAdd;
    let verticalTranslate;
    if (this._flyTweak.length === 4) {
      verticalAdd = parseInt(this._flyTweak[0] ?? "0", 10);
      verticalTranslate = parseInt(this._flyTweak[1] ?? "0", 10);
      horizontalAdd = parseInt(this._flyTweak[2] ?? "0", 10);
      horizontalTranslate = parseInt(this._flyTweak[3] ?? "0", 10);
    } else {
      horizontalAdd = 0;
      horizontalTranslate = 0;
      verticalAdd = 0;
      verticalTranslate = 0;
    }
    const flyPadding = (this._customPadding ?? this.defaultPadding) + this._defaultPaddingAdd;
    this.style.setProperty("--_focus-fly-padding", `${flyPadding}px`);
    this._focusFlyElement.style.translate = `${elmRect.left + horizontalTranslate}px ${elmRect.top + verticalTranslate + document.documentElement.scrollTop}px`;
    this._focusFlyElement.style.width = `${elmRect.width + horizontalAdd}px`;
    this._focusFlyElement.style.height = `${elmRect.height + verticalAdd}px`;
  }
  _getElementBackground(element) {
    let elm = element.parentElement || (element.getRootNode() instanceof ShadowRoot ? element.getRootNode().host : null);
    while (elm) {
      const bg = window.getComputedStyle(elm).backgroundColor;
      if (bg && this._parseColor(bg).opacity > 0.2) {
        return bg;
      }
      elm = elm.parentElement || (elm.getRootNode() instanceof ShadowRoot ? elm.getRootNode().host : null);
    }
    return "rgb(255,255,255)";
  }
  _handleKeyboardEvents(evt) {
    if (!this.validKeys.includes(evt.key)) return;
    if (evt.key === "Tab") {
      this._visible = true;
    }
    this._getAndAnimateFocus();
  }
  _hideFly() {
    this._visible = false;
  }
  _parseColor(color) {
    const tempElement = document.createElement("div");
    tempElement.style.color = color;
    tempElement.style.position = "absolute";
    document.body.appendChild(tempElement);
    const computedColor = window.getComputedStyle(tempElement).color;
    document.body.removeChild(tempElement);
    const rgbaMatch = computedColor.match(/rgba?\((\d+), (\d+), (\d+)(?:, (\d+(\.\d+)?))?\)/);
    const opacity = rgbaMatch[4] ? parseFloat(rgbaMatch[4]) : 1;
    const hsp = Math.sqrt(
      0.299 * parseFloat(rgbaMatch[1]) ** 2 + 0.587 * parseFloat(rgbaMatch[2]) ** 2 + 0.114 * parseFloat(rgbaMatch[3]) ** 2
    );
    return {
      r: parseInt(rgbaMatch[1], 10),
      g: parseInt(rgbaMatch[2], 10),
      b: parseInt(rgbaMatch[3], 10),
      opacity,
      hsp
    };
  }
  _setFocusStyles() {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync("*:focus, *:focus-visible { outline-style: none !important;  }");
    document.adoptedStyleSheets.push(sheet);
    const tabbies = focusable(document.body, { getShadowRoot: true });
    tabbies.forEach((tab) => {
      tab.getRootNode().adoptedStyleSheets.push(sheet);
    });
  }
  render() {
    return ke`<div
      id="focusfly"
      class=${Rt({ ondark: this._onDarkBackground, visible: this._visible })}
    ></div>`;
  }
};
JbFocusFly.styles = r(jb_focus_fly_default);
__decorateClass([
  n({ type: Number })
], JbFocusFly.prototype, "defaultPadding", 2);
__decorateClass([
  n({ type: Array })
], JbFocusFly.prototype, "validKeys", 2);
__decorateClass([
  e("#focusfly")
], JbFocusFly.prototype, "_focusFlyElement", 2);
__decorateClass([
  r3()
], JbFocusFly.prototype, "_onDarkBackground", 2);
__decorateClass([
  r3()
], JbFocusFly.prototype, "_visible", 2);
JbFocusFly = __decorateClass([
  customElement("jb-focus-fly")
], JbFocusFly);

export {
  JbFocusFly
};
