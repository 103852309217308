import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-globe-icon/jb-globe-icon.scss?inline
var jb_globe_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

:host {
  font-size: var(--theme-typo-p-mini-highlight-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-highlight-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  color: rgb(var(--rgb-primary, 255, 255, 255));
  cursor: pointer;
  display: var(--icon-display, inline-flex);
  gap: var(--spacing-3xs);
}

svg {
  height: 26px;
  width: 26px;
}

:host(:hover) #meridian {
  transform: rotateY(180deg);
}

#meridian {
  perspective: inherit;
  transform-origin: center;
  transition: transform 1.5s ease-in-out;
}`;

// src/components/icons/jb-globe-icon/jb-globe-icon.ts
var JbGlobeIcon = class extends r2 {
  render() {
    return ke`
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame=04">
          <path
            id="line bottom"
            d="M4 17H20"
            stroke="currentColor"
          />
          <path
            id="aquator"
            d="M2 12L22 12"
            stroke="currentColor"
          />
          <path
            id="line top"
            d="M4 7.5H20"
            stroke="currentColor"
          />
          <circle
            id="outside"
            cx="12"
            cy="12"
            r="9.5"
            stroke="currentColor"
          />
          <path
            id="meridian_2"
            d="M16.5 12C16.5 14.7006 15.9516 17.1209 15.0883 18.8475C14.2101 20.604 13.0893 21.5 12 21.5C10.9107 21.5 9.78993 20.604 8.91168 18.8475C8.04839 17.1209 7.5 14.7006 7.5 12C7.5 9.29937 8.04839 6.87912 8.91168 5.15254C9.78993 3.39605 10.9107 2.5 12 2.5C13.0893 2.5 14.2101 3.39605 15.0883 5.15254C15.9516 6.87912 16.5 9.29937 16.5 12Z"
            stroke="currentColor"
          />
          <circle
            id="meridian"
            cx="12"
            cy="12"
            r="9.5"
            stroke="currentColor"
          />
        </g>
      </svg>
      <slot></slot>
    `;
  }
};
JbGlobeIcon.styles = r(jb_globe_icon_default);
JbGlobeIcon = __decorateClass([
  customElement("jb-globe-icon")
], JbGlobeIcon);

export {
  JbGlobeIcon
};
