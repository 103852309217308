import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  JBCookie
} from "./chunk.PSCGDE4H.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-language-alert/jb-language-alert.scss?inline
var jb_language_alert_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
strong {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

section p {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

section {
  align-items: center;
  background-color: #141e55;
  display: flex;
  height: 118px;
  justify-content: center;
  left: 0;
  padding: 0 8px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0);
  transition: transform 0.2s linear;
  z-index: 551;
}
@media (min-width: 960px) {
  section {
    padding: 0 10px;
  }
}
@media (min-width: 1281px) {
  section {
    height: 66px;
    padding: 0 30px;
  }
}
section.hidden {
  overflow: hidden;
  transform: translateY(-120px);
}
section p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 959px) {
  section p {
    text-align: center;
  }
}

@media print {
  :host {
    display: none;
  }
}`;

// src/components/jb-language-alert/jb-language-alert.ts
var JbLanguageAlert = class extends r2 {
  constructor() {
    super(...arguments);
    this.texts = {};
    this._isShowing = true;
    this._isVisible = false;
    this._text = "";
  }
  events() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 0) {
        if (!this._isShowing) {
          this._isVisible = false;
        } else {
          this._isShowing = false;
        }
      }
    });
  }
  firstUpdated() {
    this.showLanguageAlert();
  }
  getCurrentPageLanguage() {
    return document.querySelector("html").getAttribute("lang");
  }
  shouldBeShown() {
    if (this.forceAlert) {
      return true;
    }
    const cookieLanguage = JBCookie.getI18nCookieData().languageIso2;
    if (!cookieLanguage) {
      return false;
    }
    const currentPageLanguage = this.getCurrentPageLanguage();
    if (!currentPageLanguage) {
      return false;
    }
    return currentPageLanguage !== cookieLanguage;
  }
  showLanguageAlert() {
    if (!this.shouldBeShown()) {
      return;
    }
    this._isVisible = true;
    const languageId = JBCookie.getI18nCookieData().language;
    this._text = this.texts[`language${languageId}`] ?? this.texts.language0;
    this.events();
    setTimeout(() => {
      this._isVisible = false;
    }, 4e3);
  }
  render() {
    const classes = {
      hidden: !this._isVisible
    };
    return ke`
      <section
        class="${Rt(classes)}"
        data-nosnippet
      >
        <p>${ae(this._text)}</p>
      </section>
    `;
  }
};
JbLanguageAlert.styles = r(jb_language_alert_default);
__decorateClass([
  n({ type: Boolean })
], JbLanguageAlert.prototype, "forceAlert", 2);
__decorateClass([
  n({ type: Object })
], JbLanguageAlert.prototype, "texts", 2);
__decorateClass([
  r3()
], JbLanguageAlert.prototype, "_isVisible", 2);
__decorateClass([
  r3()
], JbLanguageAlert.prototype, "_text", 2);
JbLanguageAlert = __decorateClass([
  customElement("jb-language-alert")
], JbLanguageAlert);

export {
  JbLanguageAlert
};
