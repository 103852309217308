import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-youtube-icon/jb-youtube-icon.scss?inline
var jb_youtube_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  height: var(--icon-height, 14.7px);
  width: var(--icon-width, 20.6px);
}

svg {
  height: var(--icon-height, 14.7px);
  width: var(--icon-width, 20.6px);
}`;

// src/components/icons/jb-youtube-icon/jb-youtube-icon.ts
var JbYoutubeIcon = class extends r2 {
  render() {
    return ke`
      <svg
        fill="currentColor"
        viewbox="0 0 22 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.25 3.926c.002.02.203 1.67.203 3.292v1.527c0 1.626-.2 3.273-.202 3.29-.011.067-.217 1.495-.88 2.164-.761.796-1.61.88-2.066.925l-.122.013c-2.623.19-6.486.21-7.01.212h-.065c-.527-.002-4.388-.023-6.998-.21l-.135-.015c-.459-.045-1.306-.129-2.07-.929-.66-.665-.867-2.093-.875-2.153a30.83 30.83 0 0 1-.203-3.297V7.218c0-1.623.2-3.272.202-3.286.009-.067.216-1.495.88-2.167C2.67.97 3.518.888 3.975.845l.12-.014c2.623-.19 6.48-.205 7.008-.208h.062c.532.003 4.393.019 7.005.206l.135.016c.456.043 1.305.124 2.068.924.66.668.867 2.096.877 2.157m-6.651 4.328a.29.29 0 0 0 .157-.259l-.156-.258-5.433-2.834-.286.009-.14.248v5.65l.14.247.152.045.134-.034L14.6 8.254"
        ></path>
      </svg>
    `;
  }
};
JbYoutubeIcon.styles = r(jb_youtube_icon_default);
JbYoutubeIcon = __decorateClass([
  customElement("jb-youtube-icon")
], JbYoutubeIcon);

export {
  JbYoutubeIcon
};
