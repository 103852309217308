import {
  Spaceable
} from "./chunk.D7EHW44T.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.CM43TOYK.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.2STITVA4.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-listing-grid/jb-listing-grid.scss?inline
var jb_listing_grid_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: rgb(var(--theme-color-signal-blue, 0 37 255));
  outline-offset: 6px;
  outline-style: var(--focus-outline-style, auto);
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.title, .title {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.title {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.375rem;
  letter-spacing: calc(var(--letter-spacing-factor, 3) * 0.063rem);
  line-height: 2.25rem;
}
@media (min-width: 1281px) {
  .title {
    font-size: 1.75rem;
    letter-spacing: calc(var(--letter-spacing-factor, 4) * 0.063rem);
    line-height: 2.5rem;
  }
}

.title {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.375rem;
  line-height: 1.75rem;
}
@media (min-width: 1281px) {
  .title {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  --items-per-column: 1;
  --column-count: 2;
  display: block;
}

.listing-grid {
  justify-content: center;
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
}
@media (min-width: 600px) {
  .listing-grid {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}
@media (min-width: 600px) {
  .listing-grid {
    padding: 0 !important;
  }
}

.title {
  line-height: 2.25rem;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 960px) {
  .title {
    line-height: 1.75rem;
  }
}
@media (min-width: 1281px) {
  .title {
    line-height: 2.25rem;
    margin-bottom: 68px;
  }
}

.columns {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media (min-width: 960px) {
  .columns {
    column-gap: 40px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(var(--column-count), 1fr);
    grid-template-rows: repeat(var(--items-per-column), min-content);
  }
}

::slotted(jb-listing-item) {
  margin-bottom: 16px;
  width: 100%;
}
@media (min-width: 960px) {
  ::slotted(jb-listing-item) {
    width: inherit;
  }
}`;

// src/components/jb-listing-grid/jb-listing-grid.ts
var JbListingGrid = class extends ChangesBackgroundColor(Spaceable(r2)) {
  firstUpdated() {
    this.style.setProperty(
      "--items-per-column",
      Math.ceil(this._grid.assignedElements().length / this.columns).toString()
    );
    this.style.setProperty("--column-count", this.columns.toString());
  }
  _getContentSpacingProperties() {
    const gridCols = this.variant === "wide" ? 12 : void 0;
    return {
      colsMdUp: gridCols,
      horizontal: this.variant ?? void 0,
      type: this.headline ? "list" : "list-without-heading"
    };
  }
  _tracking(e2) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e2, {
      component_name: "listing"
    });
    if (jbTrackingEvent) this.dispatchEvent(jbTrackingEvent);
  }
  render() {
    return ke`
      <div class=${Rt({ "listing-grid": true, [`cols${this.columns}`]: true, wide: this.variant === "wide" })}>
        ${this.headline ? ke`<h2 class="title">${this.headline}</h2>` : D}
        <div class="columns">
          <slot @jb-link:clicked=${this._tracking}></slot>
        </div>
      </div>
    `;
  }
};
JbListingGrid.styles = r(jb_listing_grid_default);
__decorateClass([
  n({ type: Number })
], JbListingGrid.prototype, "columns", 2);
__decorateClass([
  n()
], JbListingGrid.prototype, "headline", 2);
__decorateClass([
  n()
], JbListingGrid.prototype, "variant", 2);
__decorateClass([
  e("slot")
], JbListingGrid.prototype, "_grid", 2);
JbListingGrid = __decorateClass([
  customElement("jb-listing-grid")
], JbListingGrid);

export {
  JbListingGrid
};
